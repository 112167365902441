import React, { useContext } from 'react'
import {Nav, Navbar, Image, Button} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import { UserContext } from '../../context/UserContext';

const Header = () => {
    const {setUser} = useContext(UserContext);

    return (
        <>
            <Navbar bg="primary" variant="dark">
                <LinkContainer to="/">
                    <Navbar.Brand className="px-2 mx-0">
                        <Image rounded width='130' src={`${process.env.PUBLIC_URL}/ADLlogo.jpg`}/>
                    </Navbar.Brand>
                </LinkContainer>
                <Navbar.Collapse id="basic-navbar-nav" style={{justifyContent: 'right'}}>
                    <Nav className="ml-auto">
                        <Button variant="link" onClick={() => setUser({})}>Logout</Button>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <div 
                style={{
                    textAlign: 'center', 
                    color: "red",
                    fontSize: 'small'
                }}
                className="mx-3"
            >For any questions or problems please call 267-525-2470 extension 264</div>
        </>
    )
}

export default Header