import React, { useContext, useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Row } from 'react-bootstrap';
import {Form, FormGroup, Label, Input, FormFeedback} from 'reactstrap';
import { emailPatientReceipt, submitPatient } from '../../APIactions/patientRoutes';
import { submitPayment } from '../../APIactions/paymentRoutes';
import { UserContext } from '../../context/UserContext';
import Loader from '../layout/Loader';

let cardForm;

const Payments = ({prevStep, nextStep}) => {
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState("");
    const [address1, setAddress1] = useState("");
    const [address1Invalid, setAddress1Invalid] = useState(false);
    const [address2, setAddress2] = useState("");
    const [address2Invalid, setAddress2Invalid] = useState(false);
    const [city, setCity] = useState("");
    const [cityInvalid, setCityInvalid] = useState(false);
    const [state, setState] = useState("");
    const [stateInvalid, setStateInvalid] = useState(false);
    const [zip, setZip] = useState("");
    const [zipInvalid, setZipInvalid] = useState(false);
    const [token, setToken] = useState();
    const [tokenErrors, setTokenErrors] = useState([]);

    const {user, setUser} = useContext(UserContext);

    /* useEffect(() => {
        configureCardFields();
    }, []) */

    useEffect(() => {
      if(loading === false){
        configureCardFields();
      }
    }, [loading])
    
    const configureCardFields = () => {
        var {GlobalPayments} = window;
        cardForm = GlobalPayments.creditCard.form("#credit-card");

        cardForm.ready(() => {
            console.log("Registration of all credit card fields occurred"); 
        });

        /* listener for a token being successfully created */
        cardForm.on("token-success", (resp) => {
            console.log("token success");
            setTokenErrors([]);
            setToken(resp.paymentReference);
            document.getElementById("payment-form").dispatchEvent(new Event('submit', {cancelable: true, bubbles: true}));
        });

        /* listener for a token error */
        cardForm.on("token-error", (resp) => {
            console.log(resp);
            setTokenErrors([]);
            var tokenErrorReasons = [];
            resp.reasons && resp.reasons.forEach(reason => tokenErrorReasons.push(`${reason.code}: ${reason.message}`))
            setTokenErrors(tokenErrorReasons);
        });
    }

    const handlePaymentSubmit = (e) => {
        e.preventDefault();
        console.log("submitting payment...");
        setAddress1Invalid(false); setAddress2Invalid(false); setCityInvalid(false); setStateInvalid(false); setZipInvalid(false); setTokenErrors([]);
        var errorsExist = (address1 === "" || city === "" || state === "" || zip === "") ? true : false;
        address1 === "" && setAddress1Invalid(true);
        city === "" && setCityInvalid(true);
        state === "" && setStateInvalid(true);
        zip === "" && setZipInvalid(true);
        if(!errorsExist && user && user.toPay && user.toPay instanceof Array && user.toPay.length > 0 && !!user.toPay[0].bill_num){
            setLoading(true);
            submitPayment({
                address1, 
                address2, 
                city, 
                state, 
                zip, 
                token, 
                charge: user.partial ? parseFloat(user.partial) : parseFloat(user.subtotal), 
                invoice: user.toPay[0].bill_num
            }).then(data => {
                console.log(data);
                if(!!data.error){
                    if(typeof data.error === 'string' || data.error instanceof String){
                        setTokenErrors([data.error]);
                    }else{
                        var errorReasons = [];
                        !!data.error.gen && errorReasons.push(data.error.gen);
                        !!data.error.avs && errorReasons.push(data.error.avs);
                        !!data.error.cvn && errorReasons.push(data.error.cvn);
                        setTokenErrors(errorReasons);
                    }
                    setLoading(false);
                }else{
                    //process and move on
                    console.log('submission success')
                    var newUser = {...user};
                    newUser.address = {
                        address1, address2, city, state, zip
                    }
                    setUser(newUser);
                    setLoadingText("Saving payment details...");
                    if(!!data.transactionReference && !!data.transactionReference.authCode)
                        newUser.authCode = data.transactionReference.authCode;
                    submitPatient(newUser).then(() => {
                        //send email receipt if email provided, else
                        if(!!newUser.email && newUser.email !== ""){
                            setLoadingText("Sending receipt...");
                            emailPatientReceipt(newUser).then(() => {
                                setLoading(false);
                                nextStep();
                            }).catch(() => {
                                setLoading(false);
                                setLoadingText("");
                                setTokenErrors(['Error sending email receipt.']);
                            });
                        }else{
                            setLoading(false);
                            nextStep();
                        }
                    }).catch(() => {
                        setLoading(false);
                        setLoadingText("");
                        setTokenErrors(['Error saving payment info to database.']);
                    });
                }
            }).catch(err => {
                console.log(err)
            });
        }
    }
    

    return (
        <div className="px-2 mt-3">
            <ul className="pagination pagination-lg" style={{justifyContent: 'center'}}>
                <li className="page-item disabled">
                    <span className="page-link bg-transparent">1</span>
                </li>
                <li className="page-item disabled">
                    <span className="page-link bg-transparent">2</span>
                </li>
                <li className="page-item disabled">
                    <span className="page-link bg-transparent">3</span>
                </li>
                <li className="page-item active">
                    <span className="page-link bg-info">4</span>
                </li>
                <li className="page-item disabled">
                    <span className="page-link bg-transparent">5</span>
                </li>
            </ul>
            <h1 style={{textAlign: 'center'}}>Billing Information</h1>
            <Button 
                className='mx-3 px-4' 
                variant="warning" 
                onClick={prevStep}
            ><strong><i className="fas fa-chevron-left"></i>&nbsp; Back</strong></Button>
            <Row className="mx-1 px-2 py-2" style={{justifyContent: 'center'}}>
                <Card border="info" style={{maxWidth: '50rem'}}>
                    <Card.Body>
                        <Card.Title>Payment Details</Card.Title>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Invoices:</td>
                                    <td style={{minWidth: '25vw'}}></td>
                                    <td>{user && user.toPay && user.toPay.length}</td>
                                </tr>
                                <tr>
                                    <td>Subtotal:</td>
                                    <td style={{minWidth: '25vw'}}></td>
                                    <td>${user && user.subtotal && parseFloat(user.subtotal).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td>Fees:</td>
                                    <td style={{minWidth: '25vw'}}></td>
                                    <td>$0.00</td>
                                </tr>
                                {user && user.partial && (
                                    <>
                                        <tr>
                                            <td>Partial Payment:</td>
                                            <td style={{minWidth: '25vw'}}></td>
                                            <td>-(${parseFloat(user.partial).toFixed(2)})</td>
                                        </tr>
                                        <tr>
                                            <td>Remaining Balance:</td>
                                            <td style={{minWidth: '25vw'}}></td>
                                            <td>${parseFloat(+user.subtotal - +user.partial).toFixed(2)}</td>
                                        </tr>
                                    </>
                                )}
                                <tr>
                                    <td></td>
                                    <td style={{minWidth: '25vw'}}></td>
                                    <td><hr/></td>
                                </tr>
                                <tr>
                                    <td><strong>Total Charges:</strong></td>
                                    <td style={{minWidth: '25vw'}}></td>
                                    <td><strong>${user && user.partial ? parseFloat(user.partial).toFixed(2) : parseFloat(user.subtotal).toFixed(2)}</strong></td>
                                </tr>
                            </tbody>
                        </table>
                        
                    </Card.Body>
                </Card>
            </Row>
            {loading ? (
                <Loader text={loadingText && loadingText !== "" ? loadingText : `Processing request...`}/>
            ) : (
                <Row className="mt-3">
                    <Col sm={12} md={6}>
                        <h4>Billing Address:</h4>
                        <Form id="payment-form" onSubmit={handlePaymentSubmit}>
                            <FormGroup>
                                <Label>Address Line 1: <span style={{color: 'red'}}>*</span></Label>
                                <Input 
                                    value={address1} 
                                    type="text"
                                    invalid={address1Invalid}
                                    style={{maxWidth: '25rem'}}
                                    onChange={(e) => setAddress1(e.target.value)}
                                />
                                <FormFeedback>
                                    Please enter a valid address.
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label>Address Line 2:</Label>
                                <Input 
                                    value={address2} 
                                    type="text"
                                    invalid={address2Invalid}
                                    style={{maxWidth: '25rem'}}
                                    onChange={(e) => setAddress2(e.target.value)}
                                />
                                <FormFeedback>
                                    Please enter a valid address.
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label>City: <span style={{color: 'red'}}>*</span></Label>
                                <Input 
                                    value={city} 
                                    type="text"
                                    invalid={cityInvalid}
                                    style={{maxWidth: '25rem'}}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                                <FormFeedback>
                                    Please enter a valid city.
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label>State: <span style={{color: 'red'}}>*</span></Label>
                                <Input 
                                    value={state} 
                                    type="select"
                                    invalid={stateInvalid}
                                    style={{maxWidth: '12rem'}}
                                    onChange={(e) => setState(e.target.value)}
                                >
                                    <option value=""></option>
                                    <option value="AL">Alabama</option>
                                    <option value="AK">Alaska</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DE">Delaware</option>
                                    <option value="DC">District Of Columbia</option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="IA">Iowa</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="ME">Maine</option>
                                    <option value="MD">Maryland</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NY">New York</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VT">Vermont</option>
                                    <option value="VA">Virginia</option>
                                    <option value="WA">Washington</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WY">Wyoming</option>
                                </Input>
                                <FormFeedback>
                                    Please enter a valid state.
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label>Zip Code: <span style={{color: 'red'}}>*</span></Label>
                                <Input 
                                    value={zip} 
                                    type="tel"
                                    invalid={zipInvalid}
                                    style={{maxWidth: '12rem'}}
                                    onChange={(e) => setZip(e.target.value)}
                                />
                                <FormFeedback>
                                    Please enter a valid zip code.
                                </FormFeedback>
                            </FormGroup>
                        </Form>
                    </Col>
                    <Col sm={12} md={6}>
                        <h4>Credit/Debit Card Info:</h4>
                        {tokenErrors && tokenErrors.length > 0 && <Alert className="my-2" style={{textAlign: 'center'}} variant="danger">
                            {tokenErrors.map((m, index) => <p className="my-0" key={index}>{m}</p>)}
                        </Alert>}
                        <div id="credit-card"></div>
                        <button hidden={true} type="submit"></button>
                    </Col>
                </Row>
            )}
            <Row className="mt-3" style={{textAlign: 'center'}}>
                <h4 className='text-danger'>Heartland Payment System Policies</h4>
                <p>ADL's Payment function is powered by the Heartland Payment System API.</p>
                <br/>
                <h5 className='text-info'><strong>Privacy Policy</strong></h5>
                <p className='text-secondary'>We respect and are committed to protecting your privacy. We may collect personally identifiable information when you visit our site. We also automatically receive and record information on our server logs from your browser including your IP address, cookie information and the page(s) you visited. We will not sell your personally identifiable information to anyone.</p>
                <br/>
                <h5 className='text-info'><strong>Security Policy</strong></h5>
                <p className='text-secondary'>Your payment and personal information is always safe. Our Secure Sockets Layer (SSL) software is the industry standard and among the best software available today for secure commerce transactions. It encrypts all of your personal information, including credit card number, name, and address, so that it cannot be read over the internet.</p>
                <br/>
                <h5 className='text-info'><strong>Refund Policy</strong></h5>
                <p className='text-secondary'>All payments are final and no refunds will be processed.</p>
                <br/>
                <h5 className='text-info'><strong>Shipping Policy</strong></h5>
                <p className='text-secondary'>All items will ship within 2 business day of the order being placed.</p>
                
            </Row>
        </div>
    )
}

export default Payments