import axios from 'axios';

export const patientLogin = async (body) => {
    try {
        const res = await axios.post('/api/softactics/patient', body);
        console.log(res.data)
        if(res.data.error){
            throw new Error(res.data.error);
        }else{
            if(
                res.data.result && 
                res.data.result instanceof Array && 
                res.data.result.length > 0
            ){
                var user = res.data.result[0];
                if(
                    user.message && 
                    user.message === "No Balance Owed"
                ){
                    return{
                        message: "No Balance Owed",
                        respcode: "000000000",
                        firstName: body.firstName,
                        lastName: body.lastName,
                        /* b_date: body.DOB, */
                        invoices: []
                    }
                }else{
                    return {
                        respcode: user.respcode,
                        firstName: user.first,
                        lastName: user.last,
                        b_date: user.b_date,
                        invoices: res.data.result,
                        toPay: [],
                        subtotal: '0',
                        address: {}
                    }
                }
            }else{
                console.log(res.data)
                throw new Error("Internal Server Error.")
            }
        }
    }catch(err){
        console.log(err);
        throw new Error(err.message ? err.message === "Request failed with status code 500" ? "Internal Server Error" : err.message : "Internal Server Error");
    }
}