import React, { useContext, useState } from 'react'
import { Alert, Button, Card, Col, Row } from 'react-bootstrap';
import { Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import { downloadReceipt, emailPatientReceipt } from '../../APIactions/patientRoutes';
import { UserContext } from '../../context/UserContext'
import Loader from '../layout/Loader';

const Confirmation = ({backToFirst}) => {
    const [email, setEmail] = useState("");
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const {user, setUser} = useContext(UserContext);

    const parseUserDate = (d) => {
        var dateArr = d.split("T")[0].split('-');
        return `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`
    }

    const onEmailSubmit = (e) => {
        e.preventDefault();
        setEmailInvalid(false); setErrorMessage("");
        if(email.trim() === "" || !emailValid()){
            setEmailInvalid(true);
        }else{
            setLoading(true);
            var newUser = {...user};
            newUser.email = email;
            emailPatientReceipt(newUser).then(() => {
                setLoading(false);
                setUser(newUser);
            }).catch(err => {
                console.log(err);
                setErrorMessage("Error submitting receipt. Please check email address and try again.");
                setLoading(false);
            })
        }
    }

    const onDownloadReceipt = () => {
        downloadReceipt(user).then(data => {
            console.log(data);
        }).catch(err => {
            console.log(err);
        })
    }

    const emailValid = () => {
        let arr;
        try{ //split the email to find errors
            if(email.trim().includes(" ")) throw new Error(`Email contains spaces: ${email}`);
            arr = email.split('@');
            if(arr.length !== 2) throw new Error(`Too many @ characters: ${email}`);
            if(arr[0] === '') throw new Error(`No user for address: ${email}`);

            var subArr = arr[1].split('.');
            if(subArr.length !== 2) throw new Error(`Too many . characters: ${email}`);
            if(subArr[0] === '' || subArr[1] === '') throw new Error(`Invalid domain format: ${email}`);
            return true;
        }catch(err){
            console.log(err)
            return false;
        }
    }

    return (
        <div className="px-2 mt-3">
            <ul className="pagination pagination-lg" style={{justifyContent: 'center'}}>
                <li className="page-item disabled">
                    <span className="page-link bg-transparent">1</span>
                </li>
                <li className="page-item disabled">
                    <span className="page-link bg-transparent">2</span>
                </li>
                <li className="page-item disabled">
                    <span className="page-link bg-transparent">3</span>
                </li>
                <li className="page-item disabled">
                    <span className="page-link bg-transparent">4</span>
                </li>
                <li className="page-item active">
                    <span className="page-link bg-info">5</span>
                </li>
            </ul>
            <h1 style={{textAlign: 'center'}}>Payment Confirmation</h1>
            <Alert 
                variant="success" 
                style={{
                    backgroundColor: 'rgb(19, 150, 125, 50%)', 
                    border: '1px solid rgb(19, 150, 125)', 
                    textAlign: 'center'
                }}
            >
                <Alert.Heading>Success! Your payment has been successfully processed.</Alert.Heading>
                <h1 style={{color: 'rgb(19, 150, 125)'}}><i className="far fa-check-circle"></i></h1>
                <strong>If there is an issue with your payment, you may be contacted later. Billing changes may take a few days to reflect in your account.</strong>
            </Alert>
            <div className="mt-4" style={{justifyContent: 'center', textAlign: 'center'}}>
                <Button 
                    onClick={backToFirst}
                    className='mx-3 px-4 py-2'
                    variant='warning' 
                >Return To Account</Button>
            </div>
            {user && user.email ? 
                <Alert 
                    variant="success" 
                    style={{
                        backgroundColor: 'rgb(19, 150, 125, 50%)', 
                        border: '1px solid rgb(19, 150, 125)', 
                        textAlign: 'center'
                    }}
                    className="mt-4"
                >
                    <strong>Receipt has been sent you your email: {user.email}</strong>
                </Alert> : 
                <>
                    {loading ? <Loader text={`Sending receipt...`}/> : (
                        <>
                            <p className='mt-4' style={{fontSize: 'small', color: 'gray', textAlign: 'center'}}><strong>Enter an email address if you want to receive a receipt via email.</strong></p>
                            {errorMessage && errorMessage !== "" && <Alert variant="danger" className='mt-3 mb-3'>{errorMessage}</Alert>}
                            <Form 
                                inline 
                                style={{display: 'inline-flex', width: '100%'}} 
                                onSubmit={onEmailSubmit}
                            >
                                <Row style={{width: '100%'}}>
                                    <Col xs={10}>
                                        <FormGroup>
                                            <Input 
                                                type="text" 
                                                placeholder="Enter email"
                                                value={email}
                                                invalid={emailInvalid}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <FormFeedback>
                                                Please enter a valid email address.
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={2} style={{textAlign: 'center'}}>
                                        <Button 
                                            type="submit" 
                                            className="bg-info border border-light px-4 py-2"
                                        >
                                            Send <i className="far fa-paper-plane"></i>
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </>
                    )}
                </>
            }
            {/* <div className="mt-2 mb-4" style={{justifyContent: 'center', textAlign: 'center'}}>
                <Button 
                    onClick={onDownloadReceipt}
                    className='px-4 py-2'
                    variant='secondary'
                >Download Receipt Instead &nbsp;&nbsp;<i className="fas fa-download"></i></Button>
            </div> */}
            <div className="mt-4 mb-4" style={{justifyContent: 'center', textAlign: 'center'}}>
                <h4 className='text-secondary'>If you have issues receiving your receipt via email, please call us at 267-525-2470 extension 264 .</h4>
            </div>
            {user && (
                <>
                    <Row className='mt-3'>
                        <Card border='primary'>
                            <Card.Body className='mx-auto' style={{width: '100%'}}>
                                <Card.Title style={{textAlign: 'center'}}>Patient Info</Card.Title>
                                <Row style={{width: '100%'}}>
                                    <Col sm={12} md={6} style={{textAlign: 'center'}}>Name: {user.firstName && user.firstName} {user.lastName && user.lastName}</Col>
                                    <Col sm={12} md={6} style={{textAlign: 'center'}}>DOB: {user.b_date && parseUserDate(user.b_date)}</Col>
                                </Row>
                                <Row style={{width: '100%'}}>
                                    {user.phone && (
                                        <Col sm={12} md={6} style={{textAlign: 'center'}}>Phone: {user.phone && user.phone}</Col>
                                    )}
                                    {user.email && (
                                        <Col sm={12} md={6} style={{textAlign: 'center'}}>Email: {user.email && user.email}</Col>
                                    )}
                                </Row>
                            </Card.Body>
                        </Card>
                    </Row>
                    <Row className="mt-3">
                        <Card border='primary'>
                            <Card.Body className='mx-auto' style={{width: '100%'}}>
                                <Card.Title style={{textAlign: 'center'}}>Billing Info</Card.Title>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Invoices Count:</td>
                                                <td style={{minWidth: '25vw'}}></td>
                                                <td>{user && user.toPay && user.toPay.length}</td>
                                            </tr>
                                            <tr>
                                                <td>Subtotal:</td>
                                                <td style={{minWidth: '25vw'}}></td>
                                                <td>${user && user.subtotal && parseFloat(user.subtotal).toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td>Fees:</td>
                                                <td style={{minWidth: '25vw'}}></td>
                                                <td>$0.00</td>
                                            </tr>
                                            {user && user.partial && (
                                                <>
                                                    <tr>
                                                        <td>Partial Payment:</td>
                                                        <td style={{minWidth: '25vw'}}></td>
                                                        <td>-(${parseFloat(user.partial).toFixed(2)})</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Remaining Balance:</td>
                                                        <td style={{minWidth: '25vw'}}></td>
                                                        <td>${parseFloat(+user.subtotal - +user.partial).toFixed(2)}</td>
                                                    </tr>
                                                </>
                                            )}
                                            <tr>
                                                <td></td>
                                                <td style={{minWidth: '25vw'}}></td>
                                                <td><hr/></td>
                                            </tr>
                                            <tr>
                                                <td><strong>Total Charges:</strong></td>
                                                <td style={{minWidth: '25vw'}}></td>
                                                <td><strong>${user && user.partial ? parseFloat(user.partial).toFixed(2) : parseFloat(user.subtotal).toFixed(2)}</strong></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <Card.Title className='mt-3' style={{textAlign: 'center'}}>Billing Address</Card.Title>
                                <div className='mt-3' style={{display: 'flex', justifyContent: 'center'}}>
                                    {user.address && (
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>{user.address.address1 && user.address.address1}{user.address.address2 && `, ${user.address.address2}`}</td>
                                                </tr>
                                                <tr>
                                                    <td>{user.address.city && user.address.city}{user.address.state && `, ${user.address.state}`}</td>
                                                </tr>
                                                <tr>
                                                    <td>{user.address.zip && user.address.zip}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                                <div className='mt-3'>
                                    <p className='text-success' style={{textAlign: 'center', fontSize: 'large'}}>
                                        <strong>ADL does not capture any specific credit/debit card information. All online payments are processed through the Heartland Payment System.</strong>
                                    </p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Row>
                </>
            )}
            
        </div>
    )
}

export default Confirmation