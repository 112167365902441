import axios from 'axios';

export const submitPayment = async (body) => {
    try {
        const res = await axios.post('/api/heartland/payment', body);
        return res.data;
    }catch(err){
        console.log(err);
        throw err;
    }
}