import React, { useState } from 'react'
import Payments from './Payments';
import PendingPayments from './PendingPayments';
import Confirmation from './Confirmation';
import Contact from './Contact';
import PartialPayment from './PartialPayment';

const steps = ["PendingPayments", "Partial Payment", "Contact", "Payments", "Confirmation"];

const Account = () => {
  const [step, setStep] = useState(steps[0]);

  const nextStep = () => {
    setStep(steps.indexOf(step) < 4 ? steps[steps.indexOf(step) + 1] : step); 
  }

  const prevStep = () => {
    setStep(steps.indexOf(step) > 0 ? steps[steps.indexOf(step) - 1] : step);
  }
  const backToFirst = () => {
    setStep(steps[0]);
  }

  return (
    <div className='m-3'>
        {step && (
          <>
            {{
              "PendingPayments": <PendingPayments nextStep={nextStep}/>,
              "Partial Payment": <PartialPayment prevStep={prevStep} nextStep={nextStep}/>,
              "Contact": <Contact prevStep={prevStep} nextStep={nextStep}/>,
              "Payments": <Payments prevStep={prevStep} nextStep={nextStep}/>,
              "Confirmation": <Confirmation backToFirst={backToFirst}/>
            }[step]}
          </>
        )}
    </div>
  )
}

export default Account