import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Col, Row, Alert, Navbar, Image } from 'react-bootstrap';
import {Form, FormGroup, Label, Input} from 'reactstrap';
import { patientLogin } from '../../APIactions/loginRoutes';
import { UserContext } from '../../context/UserContext';
import Loader from '../layout/Loader';

const Login = () => {
    const [loadingUser, setLoadingUser] = useState(false);
    const [patientLoggingIn, setPatientLoggingIn] = useState(false);
    const [clientLoggingIn, setClientLoggingIn] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [firstNameInvalid, setFirstNameInvalid] = useState(false);
    const [lastName, setLastName] = useState("");
    const [lastNameInvalid, setLastNameInvalid] = useState(false);
    /* const [DOB, setDOB] = useState(""); */
    /* const [DOBInvalid, setDOBInvalid] = useState(false); */
    const [payID, setPayID] = useState("");
    const [payIDInvalid, setPayIDInvalid] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const {user, setUser} = useContext(UserContext);

    useEffect(() => {
      if(!!user && !!user.respcode){
        console.log("ready to redirect");
        window.location.href = "/"
      }
    }, [user])
    

    const togglePatientLoggingIn = () => {
        setClientLoggingIn(false);
        clearForm();
        setErrorMessages([]);
        setPatientLoggingIn(prevState => !prevState);
    }

    const toggleClientLoggingIn = () => {
        setPatientLoggingIn(false);
        clearForm();
        setErrorMessages([]);
        setClientLoggingIn(prevState => !prevState);
    }

    const submitPatientLogin = (e) => {
        e.preventDefault();
        setFirstNameInvalid(false); setLastNameInvalid(false); /* setDOBInvalid(false); */ setPayIDInvalid(false);
        var failureMessages = [];
        firstName === "" && failureMessages.push("Please enter a first name."); firstName === "" && setFirstNameInvalid(true);
        lastName === "" && failureMessages.push("Please enter a last name."); lastName === "" && setLastNameInvalid(true);
        /* DOB === "" && failureMessages.push("Please enter a date of birth."); DOB === "" && setDOBInvalid(true); */
        payID === "" && failureMessages.push("Please enter a Payment Verification ID."); payID === "" && setPayIDInvalid(true);
        !/^\d+$/.test(payID) && failureMessages.push("Please enter a valid Payment Verification ID."); !/^\d+$/.test(payID) && setPayIDInvalid(true);
        if(failureMessages.length > 0){
            setErrorMessages(failureMessages);
        }else{
            setLoadingUser(true);
            patientLogin({firstName, lastName, /* DOB, */ payID: +payID}).then(user => {
                //setErrorMessages([]);
                console.log(user);
                setUser(user);
            }).catch(err => {
                setLoadingUser(false);
                setErrorMessages([err.message]);
                console.log(err);
            });
        }
    }

    const clearForm = () => {
        setFirstNameInvalid(false); setLastNameInvalid(false); /* setDOBInvalid(false); */ setPayIDInvalid(false);
        setFirstName(""); setLastName(""); /* setDOB(""); */ setPayID("");
    }

    return (
        <>
            <Navbar bg="primary" variant="dark">
                <Navbar.Brand className="px-2 mx-auto">
                    <Image rounded width='130' src={`${process.env.PUBLIC_URL}/ADLlogo.jpg`}/>
                </Navbar.Brand>
            </Navbar>
            <div 
                style={{
                    textAlign: 'center', 
                    color: "red",
                    fontSize: 'small'
                }}
                className="mx-3"
            >For any questions or problems please call 267-525-2470 extension 264</div>
            <div className="mt-3" style={{textAlign: 'center'}}>
                <h1>Bill Payment Login</h1>
                {loadingUser ? <Loader text={`Loading user details...`}/> : (
                    <>
                        <Row className='mt-3' style={{justifyContent: 'center'}}>
                            {!patientLoggingIn && !clientLoggingIn && (
                                <>
                                    <Col sm={12} md={6}>
                                        <Card className='border-primary mt-3' style={{maxWidth: '30rem', minHeight: '12rem', margin: 'auto'}}>
                                            <Card.Body className="d-flex flex-column" style={{alignItems: 'center'}}>
                                                <Card.Title>Patient Login</Card.Title>
                                                <Card.Text className='mt-3'>Click here if you are a patient and trying to pay your invoice.</Card.Text>
                                                <Button 
                                                    className='mt-auto' 
                                                    style={{width: 'fit-content'}} 
                                                    variant='primary'
                                                    onClick={togglePatientLoggingIn}
                                                >Patient Login</Button>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <Card className='border-danger mt-3' style={{maxWidth: '30rem', minHeight: '12rem', margin: 'auto'}}>
                                            <Card.Body className="d-flex flex-column" style={{alignItems: 'center'}}>
                                                <Card.Title>Client Login</Card.Title>
                                                <Card.Text className='mt-3'>*** UNDER CONSTRUCTION ***{/* Click here if you are a facility serviced by ADL and trying to pay your bill. */}</Card.Text>
                                                <Button 
                                                    className='mt-auto' 
                                                    style={{width: 'fit-content'}} 
                                                    variant='danger'
                                                    /* onClick={toggleClientLoggingIn} */
                                                >Coming Soon!</Button>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </>
                            )} 
                            {patientLoggingIn && (
                                <>
                                    <Col sm={12}>
                                        <Card className='border-primary mt-3' style={{maxWidth: '25rem', margin: 'auto'}}>
                                            <Card.Body>
                                                <Card.Title>Patient Login</Card.Title>
                                                <Card.Text><strong style={{fontSize: 'small'}}>All information should match what you see on your invoice. All fields are required.</strong></Card.Text>
                                                {errorMessages && errorMessages.length > 0 && <Alert className="my-2" style={{textAlign: 'center'}} variant="danger">
                                                    {errorMessages.map((m, index) => <p className="my-0" key={index}>{m}</p>)}
                                                </Alert>}
                                                <Form style={{textAlign: 'left'}} onSubmit={submitPatientLogin}>
                                                    <FormGroup>
                                                        <Label>First Name:</Label>
                                                        <Input 
                                                            type="text" 
                                                            value={firstName} 
                                                            onChange={(e) => setFirstName(e.target.value)}
                                                            invalid={firstNameInvalid}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Last Name:</Label>
                                                        <Input 
                                                            type="text" 
                                                            value={lastName} 
                                                            onChange={(e) => setLastName(e.target.value)}
                                                            invalid={lastNameInvalid}
                                                        />
                                                    </FormGroup>
                                                    {/* <FormGroup>
                                                        <Label>Date of Birth:</Label>
                                                        <Input 
                                                            type="date" 
                                                            value={DOB} 
                                                            onChange={(e) => setDOB(e.target.value)}
                                                            invalid={DOBInvalid}
                                                        />
                                                    </FormGroup> */}
                                                    <FormGroup>
                                                        <Label>Payment Verification ID:</Label>
                                                        <Input 
                                                            type="text" 
                                                            value={payID} 
                                                            onChange={(e) => setPayID(e.target.value)}
                                                            invalid={payIDInvalid}
                                                        />
                                                    </FormGroup>
                                                    <Row className='mt-3'>
                                                        <Col style={{textAlign: 'center'}}>
                                                            <Button className='px-4' onClick={togglePatientLoggingIn}>Back</Button>
                                                        </Col>
                                                        <Col style={{textAlign: 'center'}}>
                                                            <Button className='px-4' type='submit'>Submit</Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </>
                            )}
                            {clientLoggingIn && (
                                <>
                                    <Col sm={12}>
                                        <Card className='border-danger mt-3' style={{maxWidth: '25rem', margin: 'auto'}}>
                                            <Card.Body>
                                                <Card.Title>Client Login</Card.Title>
                                                <Card.Text><strong style={{fontSize: 'small'}}>All information should match what you see on your invoice.</strong></Card.Text>
                                                <Form style={{textAlign: 'left'}}>
                                                    <FormGroup>
                                                        <Label>First Name:</Label>
                                                        <Input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Last Name:</Label>
                                                        <Input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                                                    </FormGroup>
                                                    {/* <FormGroup>
                                                        <Label>Date of Birth:</Label>
                                                        <Input type="text" value={DOB} onChange={(e) => setDOB(e.target.value)}/>
                                                    </FormGroup> */}
                                                    <FormGroup>
                                                        <Label>Payment Verification ID:</Label>
                                                        <Input type="text" value={payID} onChange={(e) => setPayID(e.target.value)}/>
                                                    </FormGroup>
                                                    <Row className='mt-3'>
                                                        <Col style={{textAlign: 'center'}}>
                                                            <Button className='px-4' variant="danger" onClick={toggleClientLoggingIn}>Back</Button>
                                                        </Col>
                                                        <Col style={{textAlign: 'center'}}>
                                                            <Button className='px-4' variant="danger">Submit</Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </>
                            )}
                        </Row>
                    </>
                )}
            </div>
        </>
    )
}

export default Login