import React, { useContext, useState, useEffect } from 'react'
import { Alert, Button, Card, Col, FormText, Row } from 'react-bootstrap';
import {Form, FormGroup, Label, Input} from 'reactstrap';
import { UserContext } from '../../context/UserContext';

const Contact = ({prevStep, nextStep}) => {
    const [phone, setPhone] = useState("");
    const [phoneInvalid, setPhoneInvalid] = useState(false);
    const [email, setEmail] = useState("");
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const {user, setUser} = useContext(UserContext);

    useEffect(() => {
      if(!!user){
          if(!!user.phone){
              setPhone(user.phone);
          }
          if(!!user.email){
              setEmail(user.email)
          }
      }
    }, [user])
    

    const handleContactSubmit = (e) => {
        e.preventDefault()
        setPhoneInvalid(false); setEmailInvalid(false); setErrorMessages([]);
        if(email.trim() === "" && phone.trim() === ""){
            setPhoneInvalid(true); setEmailInvalid(true);
            setErrorMessages(["Please fill out one or both fields."]);
        }else{
            var errors = [];
            if(email.trim() !== "" && !emailValid()){
                setEmailInvalid(true);
                errors.push("Please enter a valid email address.");
            }
            if(phone.trim() !== "" && phone.replace(/[^0-9]/g, "").length < 10){
                setPhoneInvalid(true);
                errors.push("Please enter a complete phone number.");
            }
            if(errors.length > 0){
                setErrorMessages(errors);
            }else{
                var newUser = {...user};
                if(phone.trim() !== "")
                    newUser.phone = phone.trim();
                else
                    newUser.phone = undefined;
                if(email.trim() !== "")
                    newUser.email = email.trim();
                else
                    newUser.email = undefined;
                setUser(newUser);
                nextStep();
            }
        }
    }

    const phoneMask = (input) => {
        if(input.length === 4 && input[0] === "(" && !isNaN(input.substring(1, 4))){
            input = `${input.substring(1, 3)}`
        }else{
            input = input.replace(/[^0-9]/g, "")
            if(input.length > 10)
                return;
            else if(input.length > 6)
                input = `(${input.substring(0, 3)})-${input.substring(3, 6)}-${input.substring(6, 10)}`;
            else if(input.length > 3)
                input = `(${input.substring(0, 3)})-${input.substring(3)}`;
            else if(input.length === 3)
                input = `(${input})`;
        }
        setPhone(input);
    }

    const emailValid = () => {
        let arr;
        try{ //split the email to find errors
            if(email.trim().includes(" ")) throw new Error(`Email contains spaces: ${email}`);
            arr = email.split('@');
            if(arr.length !== 2) throw new Error(`Too many @ characters: ${email}`);
            if(arr[0] === '') throw new Error(`No user for address: ${email}`);

            var subArr = arr[1].split('.');
            if(subArr.length !== 2) throw new Error(`Too many . characters: ${email}`);
            if(subArr[0] === '' || subArr[1] === '') throw new Error(`Invalid domain format: ${email}`);
            return true;
        }catch(err){
            console.log(err)
            return false;
        }
    }

    const parseUserDate = (d) => {
        var dateArr = d.split("T")[0].split('-');
        return `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`
    }
    
    return (
        <div className="px-2 mt-3">
            <ul className="pagination pagination-lg" style={{justifyContent: 'center'}}>
                <li className="page-item disabled">
                    <span className="page-link bg-transparent">1</span>
                </li>
                <li className="page-item disabled">
                    <span className="page-link bg-transparent">2</span>
                </li>
                <li className="page-item active">
                    <span className="page-link bg-info">3</span>
                </li>
                <li className="page-item disabled">
                    <span className="page-link bg-transparent">4</span>
                </li>
                <li className="page-item disabled">
                    <span className="page-link bg-transparent">5</span>
                </li>
            </ul>
            <h1 style={{textAlign:'center'}}>Contact Information</h1>
            <Button 
                className='mx-3 px-4' 
                variant="warning" 
                onClick={prevStep}
            ><strong><i className="fas fa-chevron-left"></i>&nbsp; Back</strong></Button>
            {user && (
                <>
                    <Row className='mt-3'>
                        <Card border='primary'>
                            <Card.Body className='mx-auto' style={{width: '100%'}}>
                                <Card.Title style={{textAlign: 'center'}}>Patient Info</Card.Title>
                                <Row style={{width: '100%'}}>
                                    <Col sm={12} md={6} style={{textAlign: 'center'}}>Name: {user.firstName && user.firstName} {user.lastName && user.lastName}</Col>
                                    <Col sm={12} md={6} style={{textAlign: 'center'}}>DOB: {user.b_date && parseUserDate(user.b_date)}</Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Row>
                    <Row style={{justifyContent: 'center'}}>
                        <p 
                            className="mt-3 text-info" 
                            style={{fontSize: 'small', textAlign: 'center'}}
                        ><strong>We may need to contact you regarding your payment if there is an issue. Please fill out one or both of the following fields.</strong></p>
                        <Col xs={12} sm={8} md={6}>
                            {errorMessages && errorMessages.length > 0 && <Alert className="my-2" style={{textAlign: 'center'}} variant="danger">
                                {errorMessages.map((m, index) => <p className="my-0" key={index}>{m}</p>)}
                            </Alert>}
                            <Form onSubmit={handleContactSubmit}>
                                <FormGroup>
                                    <Label style={{fontSize: 'large'}}>Phone Number: </Label>
                                    <Input
                                        type="tel"
                                        value={phone}
                                        invalid={phoneInvalid}
                                        onChange={(e) => phoneMask(e.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label style={{fontSize: 'large'}}>Email Address: </Label>
                                    <Input
                                        type="text"
                                        value={email}
                                        invalid={emailInvalid}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <FormText><span className='text-danger'><strong>
                                        * You will not be able to receive an email receipt unless you provide an email address.    
                                    </strong></span></FormText>
                                </FormGroup>
                                <div style={{ display: 'flex', justifyContent: 'center'}}>
                                    <Button 
                                        className='mx-3 px-4'
                                        variant='success' 
                                        type="submit"
                                    ><strong>Next &nbsp;<i className="fas fa-chevron-right"></i></strong></Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </>
            )}
        </div>
    )
}

export default Contact