import axios from 'axios';
import { useState } from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import { getPatientRecord } from './APIactions/patientRoutes';
import Account from './components/account/Account';
import Header from './components/layout/Header';
import Login from './components/login/Login';
import { UserContext } from './context/UserContext';

axios.defaults.baseURL = process.env.REACT_APP_NODE_ENV ? 
process.env.REACT_APP_NODE_ENV === "production" ?
"https://adlbillpayment-backend.azurewebsites.net" :
"https://adlbillpayment-backend-staging.azurewebsites.net" :
"http://localhost:4000";

console.log(process.env);

function App() {
  const [currentUser, setCurrentUser] = useState({});
  
  const setUser = (user) => {
    if(currentUser && currentUser.respcode){
      setCurrentUser(user);
    }else{
      //get data from db and match phone, email, maybe past payments
      if(user.respcode){
        getPatientRecord(user.respcode).then(data => {
          if(data){
            if(data.email)
              user.email = data.email;
            if(data.phone)
              user.phone = data.phone;
            //maybe add past payments here
            setCurrentUser(user);
          }else{
            setCurrentUser(user);
          }
        }).catch(err => {
          console.log(err);
        })
      }
    }
  }

  return (
    <>
      <UserContext.Provider value={{user: currentUser, setUser}}>
        <Router>
          {currentUser && currentUser.respcode && <Header/>}
          <Routes className="mt-3" style={{maxWidth: 'none'}}>
            <Route exact path="/" element={currentUser && currentUser.respcode ? <Account/> : <Login/>}/>
            <Route path="*" element={<Navigate to="/"/>}/>
          </Routes>
        </Router>
      </UserContext.Provider>
      
    </>
  );
}

export default App;
