import axios from 'axios';
import download from 'js-file-download';

const api_key = "1F_okp_74xQR7YEyZDoVoezF0eneuFWqHbEBf3fQtUQ";

export const getPatientRecord = async (respcode) => {
    let errMessage;
    try{
        const res = await axios.get(`/api/patients/record/${respcode}`);
        if(res.data.message){
            if(res.data.message === "No records found."){
                return undefined;
            }else{
                errMessage = `Unknown error fetching patient details: ${res.data.message}`;
            }
        }else{
            return res.data;
        }
    }catch(err){
        console.log(err);
        errMessage = err
    }finally{
        if(errMessage)
            throw new Error(errMessage);
    }
}

export const submitPatient = async (body) => {
    try{
        const res = await axios.post('/api/patients/new', body);
        return res;
    }catch(err){
        console.log(err);
        throw new Error(err);
    }
}

export const emailPatientReceipt = async (body) => {
    try{
        const res = await axios.post('/api/patients/mailreceipt', body);
        return res;
    }catch(err){
        console.log(err);
        throw new Error(err);
    }
}

export const downloadReceipt = async (body) => {
    try{
        console.log(body)
        const res = await axios.post('/api/patients/downloadreceipt', body, {
            headers: {
                'Content-type': 'application/json'
            }/* ,
            responseType: 'blob' */
        });
        console.log(res.data);
        const res2 = await axios.post('http://html2pdf-env.eba-bmpvywy4.us-east-1.elasticbeanstalk.com/api/html2pdf/', {api_key: api_key, html_content: res.data.html}, {
            headers: {
                'Content-type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            responseType: 'blob'
        })
        /* const res2 = await axios.post('https://pwtiuhpybb.execute-api.us-east-1.amazonaws.com/', {html: res.data.html}, {
            headers: {
                'Content-type': 'application/json'
            },
            responseType: 'blob'
        }) */
        download(res2.data, `patientReceipt${body.respcode}.pdf`)
        return res.data;
    }catch(err){
        console.log(err);
        throw new Error(err);
    }
}