import React, { useContext, useState, useEffect } from 'react'
import { Alert, Button, Col, Row, Table} from 'react-bootstrap';
import {Label, Input} from 'reactstrap';
import { UserContext } from '../../context/UserContext'

const PartialPayment = ({prevStep, nextStep}) => {
    const [partial, setPartial] = useState(0);
    const [error, setError] = useState("");

    const {user, setUser} = useContext(UserContext);

    useEffect(() => {
      if(user && user.partial && !isNaN(user.partial)){
        setPartial(parseInt(user.partial.replace(/[^0-9]/g, "")));
      }
    }, [])
    

    const onAmountChange = (input) => {
        var val = parseInt(input.replace(/[^0-9]/g, ""));
        var convertedSubtotal = parseFloat(user.subtotal).toFixed(2) * 100;
        if(isNaN(val))
            val = 0;
        if(val < 0){
            setPartial(0);
        }else if(val > convertedSubtotal){
            return;
        }else{
            setPartial(val);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault(); setError("");
        var convertedSubtotal = parseFloat(user.subtotal).toFixed(2) * 100;
        if(isNaN(partial) || typeof partial !== 'number' || partial <= 0 || partial > convertedSubtotal){
            setError('Please enter a valid dollar amount greater than zero and less than your selected subtotal. You may also Skip this step and pay the full subtotal.')
        }else{
            var newUser = {...user};
            newUser.partial = `${parseFloat(partial / 100).toFixed(2)}`;
            setUser(newUser);
            nextStep();
        }
    }

    const handleSkip = () => {
        if(!!user.partial){
            var newUser = {...user};
            newUser.partial = undefined;
            setUser(newUser);
        }
        nextStep();
    }

    return (
        <div className="px-2 mt-3">
            <ul className="pagination pagination-lg" style={{justifyContent: 'center'}}>
                <li className="page-item disabled">
                    <span className="page-link bg-transparent">1</span>
                </li>
                <li className="page-item active">
                    <span className="page-link bg-info">2</span>
                </li>
                <li className="page-item disabled">
                    <span className="page-link bg-transparent">3</span>
                </li>
                <li className="page-item disabled">
                    <span className="page-link bg-transparent">4</span>
                </li>
                <li className="page-item disabled">
                    <span className="page-link bg-transparent">5</span>
                </li>
            </ul>
            <h1 style={{textAlign: 'center'}}>Partial Payment (Optional)</h1>
            {user && (
                <>
                    <Row>
                        <Col xs={6} style={{textAlign: 'left'}}>
                            <Button 
                                className='mx-3 px-4' 
                                variant="warning" 
                                onClick={prevStep}
                            ><strong><i className="fas fa-chevron-left"></i>&nbsp; Back</strong></Button>
                        </Col>
                        <Col xs={6} style={{textAlign: 'end'}}>
                            <Button 
                                className='mx-3 px-4' 
                                variant="warning" 
                                onClick={handleSkip}
                            ><strong>Skip &nbsp;<i className="fas fa-chevron-right"></i></strong></Button>
                        </Col>
                    </Row>
                    {error && error !== "" && (
                        <Row className='mt-4' style={{justifyContent: 'center', textAlign: 'center'}}>
                            <Col xs={12} sm={10} md={8}>
                                <Alert variant='danger'>{error}</Alert>
                            </Col>
                        </Row>
                    )}
                    <Row className='mt-4' style={{justifyContent: 'center', textAlign: 'center'}}>
                        <Col xs={12} sm={10} md={8}>
                            <p>If you would like to make a partial payment towards your bills, please enter the amount here. <strong>If you have multiple invoices, the partial payment will apply to the oldest invoice first.</strong></p>
                        </Col>
                    </Row>
                    <Row style={{justifyContent: 'center', textAlign: 'center'}}>
                        <Col xs={12} sm={10} md={8} lg={4}>
                            <Table borderless className='mb-0'>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                                                Subtotal: 
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                {user && user.subtotal && `$${parseFloat(user.subtotal).toFixed(2)}`}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row className='mt-3' style={{justifyContent: 'center'}}>
                        <Col xs={12} sm={10} md={8} lg={6}>
                            <Table borderless>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div style={{height: '3rem', display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                                                <Label>Partial Payment Amount:</Label>
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{display: 'flex', alignItems: 'center', maxWidth: '8rem'}}>
                                                $&nbsp;&nbsp;<Input 
                                                    type="tel"
                                                    className="noarrows"
                                                    onClick={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
                                                    value={partial && parseInt(partial) !== 0 ? parseFloat(partial / 100).toFixed(2) : '0.00'}
                                                    onChange={(e) => onAmountChange(e.target.value)}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row className='mt-4' style={{justifyContent: 'center', textAlign: 'center'}}>
                        <Col>
                            <Button
                                className='mx-3 px-4' 
                                variant="success"
                                onClick={handleSubmit}
                            >
                                Submit Partial Payment
                            </Button>
                        </Col>
                    </Row>
                </>
            )}
        </div>
    )
}

export default PartialPayment