import React, { useContext, useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Row, Table} from 'react-bootstrap';
import { UserContext } from '../../context/UserContext'

const PendingPayments = ({nextStep}) => {
    const [toPay, setToPay] = useState([]);
    const [subtotal, setSubtotal] = useState('0');

    const {user, setUser} = useContext(UserContext);

    useEffect(() => {
        if(toPay && user){
            if(toPay.length > 0){
            var tot = 0;
            toPay.forEach(t => {
                tot += parseFloat(t.InvBalance);
            });
            setSubtotal(`${tot}`);
            }else{
            setSubtotal('0')
            }
            var newUser = {...user};
            newUser.toPay = toPay;
            setUser(newUser);
        }
    }, [toPay]);

    useEffect(() => {
        if(subtotal && user){
            var newUser = {...user};
            newUser.subtotal = subtotal;
            setUser(newUser);
        }
    }, [subtotal])

    const toggleAddToPay = (index) => {
        if(!!toPay.find(t => t.id === index)){
            var newToPay = [...toPay]
            setToPay(newToPay.filter(t => t.id !== index));
        }else{
            var toAdd = {...user.invoices[+index]};
            toAdd.id = index;
            setToPay([...toPay, toAdd]);
        }
    }

    const parseUserDate = (d) => {
        var dateArr = d.split("T")[0].split('-');
        return `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`
    }

    return (
        <div className="px-2 mt-3">
            <ul className="pagination pagination-lg" style={{justifyContent: 'center'}}>
                <li className="page-item active">
                    <span className="page-link bg-info">1</span>
                </li>
                <li className="page-item disabled">
                    <span className="page-link bg-transparent">2</span>
                </li>
                <li className="page-item disabled">
                    <span className="page-link bg-transparent">3</span>
                </li>
                <li className="page-item disabled">
                    <span className="page-link bg-transparent">4</span>
                </li>
                <li className="page-item disabled">
                    <span className="page-link bg-transparent">5</span>
                </li>
            </ul>
            <h1 style={{textAlign:'center'}}>Billing Account Summary</h1>
            {user && (
            <>
                <Row className='mt-3'>
                    <Card border='primary'>
                        <Card.Body className='mx-auto' style={{width: '100%'}}>
                            <Card.Title style={{textAlign: 'center'}}>Patient Info</Card.Title>
                            <Row style={{width: '100%'}}>
                                <Col sm={12} md={6} style={{textAlign: 'center'}}>Name: {user.firstName && user.firstName} {user.lastName && user.lastName}</Col>
                                <Col sm={12} md={6} style={{textAlign: 'center'}}>DOB: {user.b_date && parseUserDate(user.b_date)}</Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Row>
                <Row className='mt-3'>
                    <Col sm={12} md={6}>
                        <h4>Pending Invoices:</h4>
                        {!!user.invoices && user.invoices.length > 0 && <span style={{fontSize: 'small', color: 'gray'}}><strong>Select all invoices that you will be paying towards today. <span className='text-red'>You will be able to submit a partial payment on any invoices on the next page.</span></strong></span>}
                    </Col>
                    {!!user.invoices && user.invoices.length > 0 && (
                        <Col sm={12} md={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Button 
                                className='mt-3' 
                                variant='success' 
                                disabled={toPay.length > 0 ? false: true}
                                onClick={nextStep}
                            >Submit Payment</Button>
                        </Col>
                    )}
                </Row>
                {!!user.invoices && user.invoices.length > 0 ? (
                    <>
                        <Row className='mt-3'>
                            <Table striped hover bordered>
                                <thead>
                                    <tr>
                                        <th >Select</th>
                                        <th>Services</th>
                                        <th>Price</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {user.invoices.map((iv, index) => (
                                        <tr key={index} id={index}>
                                            <td><input 
                                            type="checkbox"
                                            value={index}
                                            checked={!!toPay.find(t => t.id === `${index}`)}
                                            onChange={(e) => toggleAddToPay(e.currentTarget.value)}
                                            ></input></td>
                                            <td>{iv.bill_num && iv.bill_num}</td>
                                            <td>${iv.InvBalance && parseFloat(iv.InvBalance).toFixed(2)}</td>
                                            <td>{iv.date && parseUserDate(iv.date)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Row>
                        <Row>
                            <Card style={{border: 'none'}}>
                                <Card.Body className='mx-auto'>
                                    <strong>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Invoices:</td>
                                                    <td style={{minWidth: '25vw'}}></td>
                                                    <td>{toPay && toPay.length}</td>
                                                </tr>
                                                <tr>
                                                    <td>Subtotal:</td>
                                                    <td style={{minWidth: '25vw'}}></td>
                                                    <td>${subtotal && parseFloat(subtotal).toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Partial:</td>
                                                    <td style={{minWidth: '25vw'}}></td>
                                                    <td>(Next Page)</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </strong>
                                </Card.Body>
                            </Card>
                        </Row>
                    </>
                ) : (
                    <Row>
                        <Alert 
                            variant="success" 
                            style={{
                                backgroundColor: 'rgb(19, 150, 125, 50%)', 
                                border: '1px solid rgb(19, 150, 125)', 
                                textAlign: 'center'
                            }}
                        >
                            <Alert.Heading>No invoices to show.</Alert.Heading>
                            <strong>It looks like we have no pending payments for you. If you feel this is in error, please call (267) 525-2470 ext. 0 .</strong>
                        </Alert>
                    </Row>
                )}
            </>
            )}
        </div>
    )
}

export default PendingPayments