import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loader = ({text}) => {
    return (
        <div style={{
            display: 'flex',
            flexFlow: 'column wrap',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '2rem'
        }}>
            <Spinner animation='border' role='status' style={{
                width: '100px',
                height: '100px'
            }}></Spinner>
            {text && (
                <span className="my-3">{text}</span>
            )}
        </div>
    )
}

export default Loader